<template>
  <div>
    <div class="header">
      <img :src="$store.state.config.match_banner" />
    </div>
    <div class="content">
      <Menu @change="menuChange" @search="search"></Menu>

      <MatchList
        v-if="list.length != 0"
        :list="list"
        :isShow="true"
        :total="total"
        class="list"
        @page="pageChange"
      ></MatchList>
      <Loading v-if="isShow"></Loading>
      <Lack v-if="list.length == 0" :imgSrc="require('@/assets/image/img/12.png')" text="暂无活动"></Lack>
    </div>
  </div>
</template>

<script>
import { matchList } from '@/service/api/match'
import Lack from '@/components/lack/lack.vue'
import Menu from '@/components/menu/menu.vue'
import MatchList from '@/components/list/match.vue'
export default {
  components: { Menu, Lack, MatchList },
  data() {
    return {
      params: {},
      list: [],
      isShow: true
    }
  },
  mounted() {},
  methods: {
    // 赛事列表
    async matchList() {
      const res = await matchList(this.params)
      this.list = res.msg.data
      this.total = res.msg.total
      this.isShow = false
    },
    menuChange(params) {
      this.params = params
      // 赛事列表
      this.matchList()
    },
    search(key) {
      this.params.page = 1
      this.params.key_words = key
      // 赛事列表
      this.matchList()
    },
    pageChange(page) {
      this.params.page = page
      // 赛事列表
      this.matchList()
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  height: 300px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.list {
  margin-top: 20px;
  margin-bottom: 40px;
}
</style>
